import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

let routes = [{
    // will match everything
    path: '*',
    component: () =>
        import('../views/404.vue'),
},
{
    path: '/',
    name: 'Sign-In',
    redirect: '/sign-in',
},
{
    path: '/dashboard',
    name: 'Dashboard',
    layout: "dashboard",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
        import( /* webpackChunkName: "dashboard" */ '../views/Dashboard.vue'),
},
{
    path: '/layout',
    name: 'Layout',
    layout: "dashboard",
    component: () =>
        import('../views/Layout.vue'),
},
{
    path: '/tables',
    name: 'Tables',
    layout: "dashboard",
    component: () =>
        import('../views/Tables.vue'),
},
{
    path: '/admin_registration',
    name: "Ro'yxatga oluvchilar",
    layout: "dashboard",
    component: () =>
        import('../views/admin_registration.vue'),
},
{
    path: '/admin_laboratory',
    name: "Laboratoriyalar",
    layout: "dashboard",
    component: () =>
        import('../views/admin_laboratory.vue'),
},
{
    path: '/admin_doctor',
    name: "Shifokorlar",
    layout: "dashboard",
    component: () =>
        import('../views/admin_doctor.vue'),
},
{
    path: '/admin_patients',
    name: "Bemorlar",
    layout: "dashboard",
    component: () =>
        import('../views/admin_patients.vue'),
},
{
    path: '/patients',
    name: 'Bemorlar',
    layout: "dashboard",
    component: () =>
        import('../views/Bemorlar.vue'),
},
{
    path: '/laboratory',
    name: 'Laboratoriya',
    layout: "dashboard",
    component: () =>
        import('../views/Laboratory.vue'),
},
{
    path: '/analysis',
    name: 'Shifokor tahlili',
    layout: "dashboard",
    component: () =>
        import('../views/analysis.vue'),
},
{
    path: '/register_patient',
    name: "Ro'xyatdan o'tkazish",
    layout: "dashboard",
    component: () =>
        import('../views/Register_patient.vue'),
},
{
    path: '/laboratory_patient',
    name: "Laboratoriya tekshiruvi",
    layout: "dashboard",
    component: () =>
        import('../views/Laboratory_patient.vue'),
},
{
    path: '/analysis_patient',
    name: "Shifokor tekshiruvi",
    layout: "dashboard",
    component: () =>
        import('../views/analysis_patient.vue'),
},
{
    path: '/analysis_patient_data',
    name: "Shifokor tekshiruvi",
    layout: "dashboard",
    component: () =>
        import('../views/analysis_patient_data.vue'),
},
{
    path: '/print_analysis',
    name: "Shifokor tekshiruvi",
    layout: "dashboard",
    component: () =>
        import('../views/print_analysis.vue'),
},
{
    path: '/profile_registration',
    name: "Profil",
    layout: "dashboard",
    component: () =>
        import('../views/profile_registration.vue'),
},
{
    path: '/profile_laboratory',
    name: "Profil",
    layout: "dashboard",
    component: () =>
        import('../views/profile_laboratory.vue'),
},
{
    path: '/patient_laboratory',
    name: "Laboratoriya tekshiruvi",
    layout: "dashboard",
    component: () =>
        import('../views/Patient_laboratory.vue'),
},
{
    path: '/patient_laboratory_edit',
    name: "Laboratoriya tekshiruvini tahrirlash",
    layout: "dashboard",
    component: () =>
        import('../views/Patient_laboratory_edit.vue'),
},
{
    path: '/profile_doctor',
    name: "Profil",
    layout: "dashboard",
    component: () =>
        import('../views/profile_doctor.vue'),
},
{
    path: '/laboratory_analysis',
    name: "Shifokor tashxisi",
    layout: "dashboard",
    component: () =>
        import('../views/Laboratory_analysis.vue'),
},
{
    path: '/laboratory_patient_data',
    name: "Laboratoriya tekshiruvi",
    layout: "dashboard",
    component: () =>
        import('../views/Laboratory_patient_data.vue'),
},
{
    path: '/billing',
    name: 'Billing',
    layout: "dashboard",
    component: () =>
        import('../views/Billing.vue'),
},
{
    path: '/rtl',
    name: 'RTL',
    layout: "dashboard-rtl",
    meta: {
        layoutClass: 'dashboard-rtl',
    },
    component: () =>
        import('../views/RTL.vue'),
},
{
    path: '/Profile',
    name: 'Profile',
    layout: "dashboard",
    meta: {
        layoutClass: 'layout-profile',
    },
    component: () =>
        import('../views/Profile.vue'),
},
{
    path: '/sign-in',
    name: 'Sign-In',
    component: () =>
        import('../views/Sign-In.vue'),
},
{
    path: '/qrcode',
    name: 'qrcode',
    component: () =>
        import('../views/qrcode.vue'),
},
{
    path: '/sign-up',
    name: 'Sign-Up',
    meta: {
        layoutClass: 'layout-sign-up',
    },
    component: () =>
        import('../views/Sign-Up.vue'),
},
]

// Adding layout property from each route to the meta
// object so it can be accessed later.
function addLayoutToRoute(route, parentLayout = "default") {
    route.meta = route.meta || {};
    route.meta.layout = route.layout || parentLayout;

    if (route.children) {
        route.children = route.children.map((childRoute) => addLayoutToRoute(childRoute, route.meta.layout));
    }
    return route;
}

routes = routes.map((route) => addLayoutToRoute(route));

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            return {
                selector: to.hash,
                behavior: 'smooth',
            }
        }
        return {
            x: 0,
            y: 0,
            behavior: 'smooth',
        }
    }
})

export default router