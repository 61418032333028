<template>
  <!-- Layout Footer -->
  <a-layout-footer>
    <a-row type="flex">
      <a-col :span="24" :md="23">
        <p class="copyright" style="padding-top: 20px; text-align: right">
          ©
          {{
            lang == "uz" ? "Kardiologiya klinikasi" : "Кардиологическая клиника"
          }}
        </p>
      </a-col>

      <!-- <a-col :span="24" :md="12" class="footer-control">
				<a-menu mode="horizontal">
					<a-menu-item>Creative Tim</a-menu-item>
					<a-menu-item>About Us</a-menu-item>
					<a-menu-item>Blog</a-menu-item>
					<a-menu-item>License</a-menu-item>
				</a-menu>
			</a-col> -->
    </a-row>
  </a-layout-footer>
  <!-- / Layout Footer -->
</template>

<script>
export default {
  data() {
    return {
      lang: null,
    };
  },
  mounted() {
    if (localStorage.getItem("lang")) {
      this.lang = localStorage.getItem("lang");
    } else {
      localStorage.setItem("lang", "uz");
    }
  },
};
</script>
